.wrapper-app {
  margin: 0 auto;
  display: block;
  margin-top: 5px;
  margin-bottom: 25px;
  text-align: center;
  border: 0px solid rgba(245, 13, 187, 0.932);
  max-width: 470px;
  text-align: center;
  background: linear-gradient(to bottom, #d2ebf3 10%, #f7cf75 130%);
  font-family: "Poppins", sans-serif;
  color: hsl(-510deg 39% 56%);
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.5), 0 0px 88px 0 rgba(0, 0, 0, 0.5);
}
