div#dairy.col-4 {
  text-align: center;
  margin: 5px;
  padding: 5px;
  max-width: 27%;
  margin: 5px auto 10px;
}

div#dairy.col-4:hover {
  border-radius: 12px;
  background: linear-gradient(to bottom, #fae8bf 10%, #fcd57c 130%);
  box-shadow: 0 2px 20px 0 rgba(39, 37, 37, 0.842),
    0 0px 0px 0 rgba(31, 29, 29, 0.842);
}

.weather-icon canvas {
  margin: 0px auto;
  display: block;
  width: 60%;
}
