.overview {
  margin-bottom: 15px;
  text-align: center;
}

h1 {
  font-size: 46px;
  margin: 0px auto 15px;
}

#description {
  font-size: 25px;
  list-style: none;
  text-transform: capitalize;
  margin: 0 auto;
}

.weather-temperature canvas {
  margin: 0px auto;
  display: block;
}
