#city-input {
  color: rgb(110, 105, 105);
}

#search-button {
  color: #5092da;
  border-color: #649cd8;
  font-size: 16px;
  padding: 7px 0 6px 2px;
}

#search-button:hover {
  color: white;
  border-color: #5092da;
  background-color: #5092da;
}

img#lupa {
  width: 53%;
}

#current {
  font-size: 16px;
  padding: 7px 0 6px 2px;
}

img#pin-icon {
  width: 55%;
}
